import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

function Home() {
  const [courses, setCourses] = useState([]);
  const [posts, setPosts] = useState([]);
  const [latestPromotion, setLatestPromotion] = useState({});
  const [promotionCourses, setPromotionCourses] = useState([]);

  useEffect(() => {
    document.title = 'OACA';
  }, []);
  async function fetchData() {
    // fetch recent posts
    await fetch('/.netlify/functions/posts?recent=6&fields=coverImageURL&fields=title')
      .then((response) => response.json())
      .then((response) => setPosts(response));

    // fetch recent courses
    await fetch('/.netlify/functions/courses?recent=6&fields=coverImageURL&fields=title')
      .then((response) => response.json())
      .then((responseCourses) => setCourses(responseCourses));

    // fetch latest promotion
    await fetch('/.netlify/functions/promotions?recent=1')
      .then((response) => response.json())
      .then((responsePromotion) => {
        setLatestPromotion(responsePromotion[0]);

        if (responsePromotion.length > 0) {
          // fetch promoted courses
          const ids = responsePromotion[0].courseIds.map((id) => `ids=${id}`).join('&');
          fetch(`/.netlify/functions/courses?${ids}&fields=coverImageURL&fields=title`)
            .then((response) => response.json())
            .then((responsePromotionCourses) => setPromotionCourses(responsePromotionCourses));
        }
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  function carouselSection(data, slug) {
    return (
      <div className="m-auto flex w-full justify-center bg-color-4 px-[10%] py-4 shadow-section">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          dynamicHeight
          className={`
            m-4 w-96 w-96 overflow-hidden rounded border-2 border-white transition-all ease-in-out

            hover:-translate-y-2 hover:shadow-card
          `}
        >
          {data.map((item) => (
            <Link
              style={{
                backgroundImage: `url('${item.coverImageURL}/m/400x400')`,
              }}
              to={`${slug}${item._id}`}
              className="flex h-96 flex-col justify-end bg-center"
            >
              <div className="bg-color-1 pb-8 pt-2 text-white opacity-80">
                <p>{item.title}</p>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-wrap items-center">
      <h1 className="mb-2 mt-4 text-center text-3xl">Online Academy of Chinese Arts</h1>
      {latestPromotion && (
        <div
          className={`
            m-auto flex w-full flex-col items-center justify-center bg-color-5 px-[10%] py-4
            shadow-section
          `}
        >
          <h2 className="mb-2 mt-4 text-center text-3xl text-color-1">{latestPromotion.note}</h2>
          {latestPromotion?.endDate && (
            <p className="text-color-1 font-ia text-sm opacity-80">{`promotion ends ${latestPromotion.endDate}`}</p>
          )}
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            dynamicHeight
            className={`
              w-96 w-96 overflow-hidden rounded border-2 border-white transition-all ease-in-out

              hover:-translate-y-2 hover:shadow-card-highlight
            `}
          >
            {promotionCourses.map((item) => (
              <Link
                style={{
                  backgroundImage: `url('${item.coverImageURL}/m/400x400')`,
                }}
                to={`/courses/${item._id}`}
                className="flex h-96 flex-col justify-end bg-center"
              >
                <div className="bg-color-1 pb-8 pt-2 text-white opacity-80">
                  <p>{item.title}</p>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>
      )}
      <div className="w-full bg-color-2 py-4 text-white">
        <p className="mx-auto max-w-2xl px-2">
          We are an education institution where various classes and programs regarding Chinese arts
          are provided to students from all over the world. The academy gathers experienced teachers
          in different fields such as Wudang Kungfu, Taichi, Calligraphy and Chinese traditional
          medicine to teach students authentic traditional Chinese Arts. It is based in Wudang
          Mountain, a holy and sacred place for Taoism and the origin place of Wudang Taichi and
          Kungfu. The academy’s mission is to spread authentic Chinese arts to more people and build
          a communication channel for people from different parts of the world.
        </p>
      </div>
      {carouselSection(courses, '/courses/')}
      <div className="w-full bg-color-2 py-4 text-white">
        <p className="mx-auto max-w-2xl px-2">
          Through different programs, students will be able to have a better understanding and
          connection with Chinese culture and Chinese arts, and while mastering the skills and
          content, it is a unique chance for students to connect with their inner self and get
          access to mysterious and ancient civilization. It is a physical and spiritual practice.
          Our teachers will guide the students through the whole journey and demonstrate as vivid
          and detailed as possible.
        </p>
      </div>
      {carouselSection(posts, '/posts/')}
      <div className="w-full bg-color-2 py-4 text-white">
        <p className="mx-auto max-w-2xl px-2">
          One of the academy’s ultimate goals is that students could enjoy and improve themselves
          through learning with joy and self-motivation. Join us and start the journey!
        </p>
      </div>
      <h1 className="mb-2 mt-4 text-center text-3xl">Contact Us</h1>
      <div className="w-full bg-color-2 py-4 text-white">
        <p className="mx-auto max-w-2xl px-2">
          If you wish to contact us, please reach out through the following platforms.
        </p>
        <p className="mx-auto max-w-2xl px-2">WeChat:</p>
        <img
          className="mx-auto"
          src="https://a-us.storyblok.com/f/1019391/150x150/a33d33c2af/qr-150x150-png.webp"
          alt="wechat-qr-code"
        />
      </div>
      <div className="m-auto flex w-full justify-center bg-color-4 px-[10%] py-4 shadow-section">
        <img
          className="h-96 rounded border-2 border-white object-cover"
          src="https://a-us.storyblok.com/f/1019391/1513x640/e7fc7f11ef/title_image-png.webp/m/0x400"
          alt="slideshow"
        />
      </div>
    </div>
  );
}

export default Home;
